import React from 'react';
import './App.css';
import Home from './screens/Home';
import Projects from './screens/Projects';
import CustomNav from './components/CustomNav';
import { Parallax } from 'react-parallax';
import { Element } from 'react-scroll';

function App() {
	return (
		<>
			<Element seamless name='home'>
				<Parallax strength={800}>
					<CustomNav />
					<Home />
					{/* <Switch>
				<Route path='/home' component={Home} />
				<Route path='/projects' component={Projects} />
				<Route path='/contact' component={Contact} />
				<Route path='/' component={Home} />
			</Switch> */}
				</Parallax>
			</Element>
			<Element seamless name='projects'>
				<Parallax strength={800}>
					<Projects />
					{/* <Switch>
				<Route path='/home' component={Home} />
				<Route path='/projects' component={Projects} />
				<Route path='/contact' component={Contact} />
				<Route path='/' component={Home} />
			</Switch> */}
				</Parallax>
			</Element>
		</>
	);
}

export default App;
