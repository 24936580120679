import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import './CustomNav.css';

function CustomNav() {
	const [show, handleShow] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (window.scrollY > 100) {
				handleShow(true);
			} else {
				handleShow(false);
			}
		});
		return () => {
			window.removeEventListener('scroll', () => {});
		};
	}, []);
	return (
		<div className={`nav ${show ? 'nav__black' : ''}`}>
			<div className='nav__container'>
				<Link to='/'>
					<span className='nav__brand'>Saul Plateros</span>
				</Link>
				<div>
					<ul className='nav__links'>
						<li>
							<Link activeClass='active' to='home' spy={true} smooth={true} duration={500}>
								Home
							</Link>
						</li>
						<li>
							<Link activeClass='active' to='projects' spy={true} smooth={true} duration={500}>
								Projects
							</Link>
						</li>
						<li>
							<Link to='/resume'>Resume</Link>
						</li>

						<li>
							<Link to='/contact'>Contact</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default CustomNav;
