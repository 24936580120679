import React from 'react';
import MainTitle from '../components/MainTitle';
import './Home.css';

function Home() {
	return (
		<div className='home'>
			<div className='home__title'>
				<MainTitle title='Saul Plateros' subtitle='Full-Stack Software Developer' />
			</div>
		</div>
	);
}

export default Home;
