import React from 'react';
import './Projects.css';
function Projects() {
	return (
		<section className='projects'>
			<h1 className="projects__title">Projects</h1>
		</section>
	);
}

export default Projects;
